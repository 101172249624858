@import "~@sweetalert2/theme-dark/dark.css";
@import url("https://fonts.googleapis.com/css2?family=Schoolbell&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ubuntu&display=swap");
// @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@1,800&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Graduate&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Sora:wght@400;600&display=swap");

@import "~basscss";

/* Base Modules */
@import "~basscss-type-scale";
@import "~basscss-typography";
@import "~basscss-layout";
@import "~basscss-align";
@import "~basscss-margin";
@import "~basscss-padding";
@import "~basscss-grid";
@import "~basscss-flexbox";
@import "~basscss-position";
@import "~basscss-border";
@import "~basscss-hide";
@font-face {
  font-family: "Roadrage";
  src: local("Roadrage"), url(./images/Roadrage.woff) format("woff");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

.App {
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;

  &__body {
    position: relative;
    top: -5vw;
  }

  font-family: "Ubuntu", sans-serif;
}

* {
  box-sizing: border-box;
}

.Schoolbell-Font {
  font-family: "Schoolbell", cursive;
}

.Graduate-Font {
  font-family: "Graduate", sans-serif;
}

.Ubuntu-Font {
  font-family: "Ubuntu", sans-serif;
}

.jumbo2 {
  font-size: 80px;
}

.jumbo {
  font-size: 60px;
}

.h0 {
  font-size: 40px;
}

.green-color {
  color: rgb(0, 113, 51);
}

.green-background {
  background-color: rgb(0, 113, 51);
}

.desktop-only {
  @media only screen and (max-width: 800px) {
    display: none;
  }
}

button {
  background: none;
  border: 0px;
  cursor: pointer;
  color: white;
}

$size: 30px;
$speed: 3s;
$peakopacity: 0.7;

body {
  .arrows {
    position: relative;
    /**/
    position: absolute;
    left: 50%;
    /**/
    width: $size;
    height: $size;
    transform: translate(-50%, -50%);
    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      border-left: $size/3 solid rgba(255, 255, 255, $peakopacity);
      border-bottom: $size/3 solid rgba(255, 255, 255, $peakopacity);
      transform: translate($size/3, $size * 4/3) rotate(-45deg);
      animation: arrows $speed linear infinite;
    }
    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      border-left: $size/3 solid rgba(255, 255, 255, $peakopacity);
      border-bottom: $size/3 solid rgba(255, 255, 255, $peakopacity);
      transform: translate($size * 2/3, 0px) rotate(-45deg);
      animation: arrows $speed linear infinite $speed/-2;
    }
  }
}

@keyframes arrows {
  0% {
    border-left: $size/3 solid rgba(255, 255, 255, 0);
    border-bottom: $size/3 solid rgba(255, 255, 255, 0);
    transform: translate($size/-6, $size * 4/-6) rotate(-45deg);
  }
  10%,
  90% {
    border-left: $size/3 solid rgba(255, 255, 255, 0);
    border-bottom: $size/3 solid rgba(255, 255, 255, 0);
  }
  50% {
    border-left: $size/3 solid rgba(255, 255, 255, $peakopacity);
    border-bottom: $size/3 solid rgba(255, 255, 255, $peakopacity);
    transform: translate($size/-6, 0px) rotate(-45deg);
  }
  100% {
    border-left: $size/3 solid rgba(255, 255, 255, 0);
    border-bottom: $size/3 solid rgba(255, 255, 255, 0);
    transform: translate($size/-6, $size * 4/6) rotate(-45deg);
  }
}
