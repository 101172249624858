.Hero {
  &__extra-bg {
    background: linear-gradient(#71a6a7, #8bced4);
    height: 80px;
  }

  &__background {
    display: flex;
    justify-content: flex-end;
    height: calc(50vw);
    max-height: calc(95vh - 30px);
    padding: 60px;

    width: 100%;
    position: relative;
    text-align: left;

    @media only screen and (min-width: 1300px) {
      height: calc(85vh);
      padding: 100px;
      max-height: none;
    }

    .arrows {
      position: absolute;
      margin-left: 0;
      margin-right: 0;
      top: unset;
      bottom: 30px;
    }
  }
}
