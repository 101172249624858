.Rarity {

  .Hero {

    position: relative;

    &__content {
      color: white;
      margin: 0 auto;
      margin-top: 120px;
      max-width: 800px;;
      font-size: 1.2em;
      font-weight: 100;
      @media (max-width: 900px) {
        padding-bottom: 100px;
        margin-top: 100px;
      }
      @media only screen and (min-width: 1300px) {
        margin-top: 50px;
        // max-height: calc(95vh - 150px);
      }
    }

    &__extra-bg {
        background: rgba(0,0,0,0);
        height: 80px;
        @media only screen and (min-width: 1300px) {
          height: 80px;
        }
        position: absolute;
        top: 0px;
        z-index: 1;
        width: 100%;
    }
  
    &__background {
      display: flex;
      height: calc(60vh - 80px);
      padding: 60px;
      padding-top: 0;
  
      @media only screen and (min-width: 1300px) {
        height: calc(60vh);
        max-height: none;
        // max-height: calc(95vh - 150px);
      }
  
      @media (min-width: 1300px) {
        padding: 100px;
      }

      @media (max-width: 900px) {
        height: auto;
        max-height: none;
      }
  
      width: 100%;
      position: relative;
      text-align: left;
  
      .arrows {
        position: absolute;
        margin-left: 0;
        margin-right: 0;
        top: unset;
        bottom: 30px;
      }
    }
  }
  
  
}